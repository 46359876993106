import ApiService from "./ApiService";
const apiService = new ApiService();
import Vue from "vue";
import i18n from "../i18n";
export default class CompanyServices {
  constructor() {}
  async download_company_file(file_id) {
    await apiService
      .getRequest(`companyfile/download/${file_id}`)
      .then((resp) => {
        resp = resp.content;
        let file_name = resp.fileName;
        resp = "data:application/octet-stream;base64," + resp.fileContent;
        const link = document.createElement("a");
        link.href = resp;
        link.setAttribute("download", file_name);
        document.body.appendChild(link);
        link.click();
        Vue.$toast.success(i18n.t("download_file_success"));
      })
      .catch((err) => {
        if (err.status === 404) {
          Vue.$toast.error(i18n.t("an_error_ocurred"));
        }
      });
  }
}
