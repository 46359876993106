<template>
  <div>
    <v-select
      class="mt-5"
      outlined
      item-value="value"
      item-text="text"
      v-model="selectedLanguage"
      :items="languageOptionsValueByText"
      :label="$t('language')"
      @change="handleChangeLanguage"
    >
    </v-select>
  </div>
</template>

<script>
import { languageOptionsValueByText } from "@/shared/helpers/languageHelper";

export default {
  name: "SelectBriefingLanguageComponente",
  data: () => ({
    selectedLanguage: null,
    languageOptionsValueByText,
  }),
  created() {
    const createdLanguage = this.$i18n.locale;
    this.selectedLanguage = createdLanguage;
    this.handleChangeLanguage(createdLanguage);
  },
  methods: {
    handleChangeLanguage(e) {
      this.$emit("handleChangeLanguage", e);
    },
  },
};
</script>

<style scoped></style>
