<template>
  <div v-if="!loading">
    <div class="text-center mt-8">
      <v-btn
        color="primary"
        rounded
        @click="add_file_dialog()"
        fab
        top
        right
        absolute
      >
        <v-icon large>mdi-plus</v-icon>
      </v-btn>
    </div>
    <div>
      <SelectBriefingLanguageComponent
        @handleChangeLanguage="(e) => (selectedLanguage = e)"
      />
    </div>
    <v-simple-table>
      <thead>
        <tr>
          <th>{{ $t("name") }}</th>
          <th class="text-center">
            {{ $t("download") }}
          </th>
          <th class="text-center">{{ $t("edit") }}</th>
          <th class="text-center">
            {{ $t("remove") }}
          </th>
        </tr>
      </thead>

      <tbody>
        <tr
          v-for="(file, i) in files.filter(
            (x) => gs.getLanguage(x.language) === selectedLanguage
          )"
          :key="i"
        >
          <td>{{ file.fileName }}</td>
          <td class="text-center">
            <v-btn
              class="px-2 ml-1 btnDownloadFile"
              icon
              color="primary"
              @click="download_file(file)"
              min-width="0"
              small
              :loading="fileDownloading == file"
            >
              <v-icon small>mdi-download</v-icon>
            </v-btn>
          </td>
          <td class="text-center">
            <v-btn
              class="px-2 ml-1 secondary"
              @click="edit_file_dialog(file)"
              min-width="0"
              small
            >
              <v-icon small>mdi-pencil</v-icon>
            </v-btn>
          </td>
          <td class="text-center">
            <v-btn
              class="px-2 ml-1"
              @click="open_delete_dialog(file)"
              color="red"
              min-width="0"
              small
            >
              <v-icon small>mdi-trash-can</v-icon>
            </v-btn>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
    <v-dialog v-if="dialog" v-model="dialog">
      <v-card class="dxa_modal">
        <v-card-title>
          <h4 class="dxa_modal_title h4">{{ $t("new_file") }}</h4>
        </v-card-title>
        <v-card-text>
          <v-form @submit.stop.prevent="submit()" ref="form" class="mt-5">
            <v-select
              :items="index_options"
              :label="$t('index')"
              v-model="selected_index"
              outlined
            ></v-select>
            <input
              v-if="!edit"
              @change="files_changed"
              type="file"
              id="file"
              ref="file"
              prefix
              label="File input"
            />
            <v-card-actions class="pl-0 dxa_modal_actions">
              <v-btn
                class="dxa_modal_btnSuccess"
                color="primary"
                min-width="100"
                :loading="dialog_loading"
                type="submit"
                >{{ $t("save") }}</v-btn
              >
              <v-btn
                class="dxa_modal_btnError"
                color="red"
                min-width="100"
                @click="dialog = false"
                >{{ $t("cancel") }}</v-btn
              >
            </v-card-actions>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-if="delete_dialog" v-model="delete_dialog">
      <DeleteConfirmationModal
        :obj="delete_obj"
        :name="$t('this_file')"
        @close="delete_dialog = false"
        @delete="remove_file"
      ></DeleteConfirmationModal>
    </v-dialog>
  </div>
  <v-container v-else>
    <v-progress-circular
      style="margin-left: 50%"
      indeterminate
      size="70"
      color="primary"
    ></v-progress-circular>
  </v-container>
</template>

<script>
import ApiService from "@/services/ApiService";
import GeneralServices from "@/services/GeneralServices";
import CompanyServices from "@/services/CompanyServices";
import DeleteConfirmationModal from "@/views/pages/DeleteConfirmationModal";
import SelectBriefingLanguageComponent from "../components/SelectBriefingLanguageComponent";

export default {
  name: "ManageCompanyFiles",

  components: {
    DeleteConfirmationModal,
    SelectBriefingLanguageComponent,
  },

  data: () => ({
    apiService: new ApiService(),
    gs: new GeneralServices(),
    cs: new CompanyServices(),
    loading: false,
    dialog_loading: false,
    dialog: false,
    delete_dialog: false,
    delete_obj: null,
    error: null,
    edit: false,
    files: [],
    selected_file: null,
    selected_index: null,
    fileDownloading: null,
    selectedLanguage: null,
  }),

  computed: {
    required() {
      return (value) => !!value || this.$t("required");
    },
    index_options() {
      var i;
      var return_list = [];
      for (
        i = 1;
        i <=
        this.files.filter(
          (x) => this.gs.getLanguage(x.language) === this.selectedLanguage
        ).length;
        i++
      ) {
        return_list.push(i);
      }
      if (!this.edit) {
        return_list.push(
          this.files.filter(
            (x) => this.gs.getLanguage(x.language) === this.selectedLanguage
          ).length + 1
        );
      }
      return return_list;
    },
  },
  props: {
    roundBriefing: Boolean,
    BriefingId: Number,
    company_prop: Object,
  },

  async created() {
    this.loading = true;
    this.apiService
      .getRequest(`companyfile/list/briefing/${this.BriefingId}`)
      .then((resp) => {
        this.files = resp.content;
      });
    this.loading = false;
  },

  methods: {
    async download_file(file) {
      this.fileDownloading = file;
      await this.cs.download_company_file(file.id);
      this.fileDownloading = null;
    },
    files_changed(event) {
      this.selected_file = event.target.files[0];
    },
    open_delete_dialog: function (file) {
      this.delete_obj = file;
      this.delete_dialog = true;
    },
    remove_file: async function (file) {
      this.dialog_loading = true;
      this.error = null;
      await this.apiService
        .deleteRequest("companyfile/delete/briefing", file)
        .then((result) => {
          this.files = result.content;
          this.delete_dialog = false;
        })
        .catch((error) => {
          this.$emit("error", error.body.message);
          this.error = error.body.message;
        });
      this.dialog_loading = false;
    },
    edit_file_dialog: function (file) {
      this.edit = true;
      this.selected_file = file;
      this.selected_index = file.index;
      this.dialog = true;
    },
    add_file_dialog: function () {
      this.edit = false;
      this.selected_file = null;
      this.selected_index =
        this.files.filter(
          (x) => this.gs.getLanguage(x.language) === this.selectedLanguage
        ).length + 1;
      this.dialog = true;
    },
    submit: async function () {
      if (this.dialog_loading) {
        return;
      }
      this.error = null;
      this.dialog_loading = true;

      let langId = -1;
      if (this.selectedLanguage === "pt") {
        langId = 0;
      } else if (this.selectedLanguage === "en") {
        langId = 1;
      } else {
        langId = 2;
      }

      var fd = new FormData();
      fd.append("file", this.selected_file);

      if (this.edit) {
        await this.apiService
          .postRequest("companyfile/edit/briefing", {
            File: this.selected_file,
            Index: this.selected_index,
          })
          .then((result) => {
            this.files = result.content;
            this.dialog = false;
          })
          .catch((error) => {
            this.error = error.body.message;
          });
      } else {
        // fazendo verificação na prop do roundBriefing, pois caso esteja utilizando o briefing
        // na rodada precisa ser passado o 0. Por padrão ele manda o Id da empresa como null e acaba quebrando
        await this.apiService
          .postRequest(
            `companyfile/add/briefing/${
              this.roundBriefing ? 0 : this.company_prop.CompanyId
            }/${this.BriefingId}/${langId}/${this.selected_index}`,
            fd
          )
          .then((result) => {
            this.files = result.content;
            this.dialog = false;
          })
          .catch((error) => {
            this.error = error.body.message;
          });
      }
      this.dialog_loading = false;
    },
  },
};
</script>
