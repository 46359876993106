import { LanguageEnum, LanguageTextEnum } from "@/shared/enums/LanguageEnum";

export const languageOptions = [
  {
    text: LanguageTextEnum.PT,
    value: LanguageEnum.pt,
  },
  {
    text: LanguageTextEnum.EN,
    value: LanguageEnum.EN,
  },
];

export const languageOptionsValueByText = [
  {
    text: LanguageTextEnum.PT,
    value: LanguageTextEnum.pt,
  },
  {
    text: LanguageTextEnum.EN,
    value: LanguageTextEnum.EN,
  },
];
